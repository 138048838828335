<template>
    <div>
        <div :class="'position-relative d-inline-block ' + imageCircleBorder">
            <div :class="'bg-black overflow-hidden ' + imageCircle">
                <img v-if="image" :src="imageLocation(image)" class="profile-image" :class="[{'p-2':imgPadding}, {'no-border' : noBorder}]">
            </div>
            <div v-if="!image"
                 :class="['position-absolute top-0 d-flex align-items-center justify-content-center w-100 h-100 text-white fw-bold', textSize]">
                <template v-if="title">{{ title.substring(0, 1) }}</template>
                <template v-else>
                    <font-awesome-icon icon="user" size="lg"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    components: {FontAwesomeIcon},
    title: "profileImage",

    props: {
        image: {
            type: String,
        },
        title: {
            type: String,
            description: 'first letter as replacement in case image does not exist'
        },
        size: {
            type: String,
            default: 'lg',
            description: 'large: lg || medium:md || small: sm'
        },
        imgPadding: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        }
    },


    computed: {
        imageCircle() {
            return 'img-circle-' + this.size
        },
        imageCircleBorder() {
            return 'black-img-border-' + this.size
        },
        textSize() {
            if(this.size === 'sm') {
                return 'text-sm'
            } else if(this.size === 'md' || this.size === 'nav') {
                return 'text-md'
            } else {
                return 'text-lg'
            }
        }
    },

    methods: {
        imageLocation(path) {
            if (path.includes('base64') || path.includes('logo')) {
                return path;
            }  else if (path != null) {
                return '/storage/' + path;
            } else {
                return null;
            }
        }
    }
}
</script>